<template>
	<div>
		<s-crud
			title="Lotes de Selección"
			:filter="filter"
			:config="config"
			load
			@load="uploadSap()"
			@rowSelected="rowSelected($event)"
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col lg="3">
							<s-date label="Fecha Inicio" v-model="filter.DateBegin"></s-date>
						</v-col>
						<v-col lg="3">
							<s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>

		<v-dialog
			v-model="dialog"
			persistent
			fullscreen
		>
			<resume-selection-lot :row="row" @closeDialog="closeDialog"></resume-selection-lot>
		</v-dialog>
	</div>
</template>

<script>
	import _sSelectionLotService from "@/services/FrozenProduction/SelectionLotService";
	import ResumeSelectionLot from "./ResumeSelectionLot.vue";
	export default {
		components: {
			ResumeSelectionLot,
		},

		data() {
			return {
				dialog: false,
				filter: {},
				config: {	
					service: _sSelectionLotService,
					model: {
						NumberLot: "ID",
						CchCapacity: "double",
						SecStatus: "status",
						CchStatus: "status",
						RcfDateReception:'date'
					},
					headers: [
						{ text: "Fecha", value: "RcfDateReception" },
						{ text: "Nro Lote", value: "NumberLot" },
						{ text: "Nro Guía", value: "NumberHarvestGuide" },
						{ text: "Fundo", value: "FagName" },
						{ text: "Productor", value: "PrdCardName" },
						{ text: "Cantidad Jabas", value: "LlpQuantityJaba" },
						{ text: "Peso Neto", value: "LlpWeight" },
						{ text: "Peso Guía", value: "RcfWeightGuide" },
						
					],
				},
				row: []
			}
		},

		methods: {
			rowSelected(row)
			{
				if(row.length > 0){
					this.row = row[0];
				}
				
			},

			uploadSap() {
				if(this.row.NumberLot == undefined){
					this.$fun.alert('No hay lote seleccionado', 'warning');
					return;
				}
				this.dialog = true;
				/* _sSelectionLotService.uploadSap(this.row, this.$fun.getUserId())
				.then(resp => {
					if(resp.status == 200){
						alert();
					}
				}) */
			},

			closeDialog()
			{
				this.dialog = false;
			}
		},
	}
</script>