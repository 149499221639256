<template>
	<v-card>
		<s-toolbar
			label="Subida SAP"
			dark
			:color="'#BAB6B5'"
			save
			@save="save()"
			close
			@close="closeDialog"
			
		></s-toolbar>
		<v-container>
			<v-row>
				<v-col cols="12" lg="3" md="3" sm="6">
					<s-text
						v-model="row.Serie"
						label="Serie"
					>
					</s-text>
				</v-col>
				<v-col cols="12" lg="3" md="3" sm="6">
					<s-text
						v-model="row.Number"
						label="Nro"
					>
					</s-text>
				</v-col>
				<v-col cols="12" lg="3" md="3" sm="6">
					<s-text
						v-model="row.Decrease"
						label="Merma"
					>
					</s-text>
				</v-col>
				<v-col cols="12" lg="3" md="3" sm="6">
					<s-switch
						v-model="row.IgvCheck"
						label="IGV"
					></s-switch>
				</v-col>
				<v-col cols="12" lg="3" md="3" sm="6">
					<s-switch
						v-model="row.WeightGuideCheck"
						label="Peso Guía"
					></s-switch>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	export default {
		props: {
			row: {
				type: Object,
				default: []
			},
		},
		
		data() {
			return {
				
			}
		},

		methods: {
			closeDialog() {
				this.$emit("closeDialog");
			},

			save(){
				if(this.row.Serie == ""){
					this.$fun.alert("Ingrese serie", "warning");
					return;
				}
				if(this.row.Number == ""){
					this.$fun.alert("Ingrese número", "warning");
					return;
				}
				/* if(this.row.RcfWeightGuide == null || this.row.RcfWeightGuide == 0){
					this.$fun.alert("No tiene ingresado peso de guía", "warning");
					return;
				} */
				if(this.row.LlpWeight == 0 || this.row.LlpWeight == null){
					this.$fun.alert("Lote sin pesado", "warning");
					return;
				}

				console.log('save  ', this.row);

			}
		},
	}
</script>